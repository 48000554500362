<template>
  <div>
    <dialog-loader :visible="!assistances" />
    <dashboard
      v-if="assistances"
      :assistances="assistances"
      :refresh="reFetchAssistances"
    />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import Dashboard from '@/views/dashboard/dashboard.vue'
import assistanceService from '@/services/assistanceService'
import DialogLoader from '@/views/dialogLoader.vue'
import store from '@/store'

export default {
  components: {
    DialogLoader,
    Dashboard,
  },
  setup() {
    store.commit('assistance/setAssistances', null)

    const {
      fetchAssistances,
      getAssistances,
    } = assistanceService()

    fetchAssistances()

    const assistances = computed(() => getAssistances())

    const reFetchAssistances = () => {
      fetchAssistances()
    }

    return {
      assistances,
      reFetchAssistances,
    }
  },
}
</script>

<style scoped>

</style>
