<template>
  <v-row>
    <v-col
      v-for="(product, index) in assistance.products"
      :key="index"
      cols="10"
      sm="10"
      md="8"
      lg="8"
      offset-sm="1"
      offset-md="2"
      offset-lg="2"
      offset="1"
    >
      <v-card class="ghd-border-black">
        <div class="align-start ghd-ITCAvant text-black ml-5 pt-4 text-medium">
          {{ product.prodotto.nome }}
          <v-chip
            v-if="product.need_scenario"
            class="v-chip-light-bg warning--text"
            color="warning"
            small
          >
            Selezionare uno scenario
          </v-chip>
        </div>

        <v-card-subtitle>
          Data code: {{ product.prodotto.codice_ean }}{{ product.prodotto.seriale }}
        </v-card-subtitle>

        <v-card-text>
          <div class="mb-5">
            <span>{{ product.stato.id === 2 && !assistance.closed_at ? 'In attesa di ritiro' : product.stato.label }}</span>
          </div>
          <div
            v-if="product.testo_assistenza && !product.scenario"
            v-html="product.testo_assistenza"
          ></div>
        </v-card-text>

        <v-card-text v-if="product.scenario">
          <h3>Opzione scelta</h3>
          <h4>{{ product.scenario.nome }}</h4>
          <p v-if="product.scenario.descrizione">{{ product.scenario.descrizione }}</p>
        </v-card-text>
        <v-card-text v-else>
          <h3 v-if="product.need_scenario">
            Seleziona un'opzione
          </h3>
          <div
            v-for="(scenario, index) in product.stato.scenari"
            :key="index"
            @click="openConfirmDialog(product, scenario)"
          >
            <v-card
              class="ghd-border-black mt-3"
            >
              <v-card-text>
                {{ scenario.nome }}
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <dialog-dynamic
      v-if="confirmDialogVisibile"
      :data="{title: 'Confermare scelta?', 'subtitle': 'Ti informiamo che non è possibile modificare la scelta in seguito.'}"
      @turnoff="confirmDialogVisibile = false"
    >
      <template v-slot:action>
        <div style="flex: 1; margin: 5px">
          <div>
            <v-btn
              color="primary"
              block
              style="border: 2px solid white !important;"
              @click="confirmDialogVisibile = false"
            >
              Non confermo
            </v-btn>
          </div>
        </div>
        <div style="flex: 1; margin: 5px">
          <div>
            <v-btn
              color="primary"
              block
              style="border: 2px solid white !important;"
              @click="saveScenario(productChoosed, scenarioChoosed.id)"
            >
              Confermo
            </v-btn>
          </div>
        </div>
      </template>
    </dialog-dynamic>
  </v-row>
</template>

<script>
import {
  mdiLockOpenOutline,
  mdiStarOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import store from '@/store'
import DialogDynamic from '@/views/dialogs/dialogDynamic.vue'

export default {
  name: 'ColumnLeft',
  components: { DialogDynamic },

  props: {
    assistance: {
      required: true,
      type: [Object, Array],
    },
  },
  setup(props, { emit }) {
    const sidebarData = ref(null)
    const isInvoiceSendSidebarActive = ref(false)
    const confirmDialogVisibile = ref(false)

    const productChoosed = ref(null)
    const scenarioChoosed = ref(null)

    const openSidebar = product => {
      sidebarData.value = product
      isInvoiceSendSidebarActive.value = !isInvoiceSendSidebarActive.value
    }

    const openConfirmDialog = (product, scenario) => {
      productChoosed.value = product
      scenarioChoosed.value = scenario
      confirmDialogVisibile.value = true
    }

    const saveScenario = (prodotto, scenario) => {
      const sendData = {
        code: props.assistance.code,
        prodotti: {
          [prodotto.id]: {
            scenario_id: scenario,
            data_scelta_scenario: dayjs().unix(),
          },
        },
      }

      store.dispatch('assistance/updateAssistance', sendData).then(() => {
        emit('refetchdata')
        confirmDialogVisibile.value = false
      })
    }

    return {
      icons: {
        mdiLockOpenOutline,
        mdiStarOutline,
      },
      openSidebar,
      sidebarData,
      isInvoiceSendSidebarActive,
      confirmDialogVisibile,
      productChoosed,
      scenarioChoosed,
      openConfirmDialog,
      saveScenario,
    }
  },
}
</script>

<style scoped>

</style>
