<template>
  <v-row class="">
    <v-col
      v-for="assistenza in assistances"
      :key="assistenza.id"
      cols="12"
      sm="12"
      md="6"
      lg="4"
    >
      <v-card class="ghd-border-black">
        <v-card-title class="align-start">
          <div class="text-sm ghd-ITCAvant text-black">
            {{ assistenza.code }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            class="me-n3 mt-n2"
          >
            <v-icon :color="isCompleted(assistenza).color">
              {{ isCompleted(assistenza).icon }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-list
            class="pt-1"
          >
            <v-list-item
              v-for="(product,index) in assistenza.products.slice(0,1)"
              :key="assistenza.id+product.id"
              class="d-flex px-0"
              :class="index > 0 ? 'mt-4':''"
            >
              <div class="contenitore">
                <div class="image-container">
                  <img
                    class="image"
                    :src="product.info.item_icon"
                  >
                </div>

                <div class="text-container">
                  <div class="font-weight-semibold ghd-ITCAvant text-black">
                    {{ product.prodotto.nome }}
                  </div>

                  <span class="text-xs text-no-wrap text-black">Seriale: {{ product.prodotto.seriale }}</span>
                </div>
              </div>

              <!--              <div class="d-flex align-center flex-grow-1 flex-wrap">-->
              <!--                <div>-->
              <!--&lt;!&ndash;                  <img :src="product.info.item_icon" />&ndash;&gt;-->
              <!--                  <v-img :src="product.info.item_icon"/>-->
              <!--                </div>-->
              <!--                <div>-->
              <!--                  <div class="font-weight-semibold text-no-wrap ghd-ITCAvant text-black">-->
              <!--                    {{ product.prodotto.nome }}-->
              <!--                  </div>-->
              <!--                  <span class="text-xs text-no-wrap text-black">Seriale: {{ product.prodotto.seriale }}</span>-->
              <!--                </div>-->
              <!--              </div>-->
            </v-list-item>
          </v-list>

          <div class="d-flex align-center">
            <div>
              <v-btn
                rounded
                color="primary"
                small
                @click="openAssistanceEdit(assistenza)"
              >
                Vedi assistenza
              </v-btn>
            </div>
            <div class="ml-auto text-right" >
              <v-btn
                v-if="assistenza.stato === 'GAR' && !assistenza.closed_at"
                rounded
                class="btn-gold"
                small
                @click="openAssistancePickup(assistenza)"
              >
                <span v-if="assistenza.can_store_pickup">Prenota ritiro</span>
                <span v-else>Visualizza ritiro</span>
              </v-btn>
            </div>

          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <assistance-edit
      v-if="assistanceToEdit"
      :visible="assistanceEditVisible"
      :assistance="assistanceToEdit"
      @turnoff="closeAssistanceEdit()"
    />
    <pickup-assistance
      v-if="assistanceToEdit"
      :assistance="assistanceToEdit"
      :visible="pickUpVisible"
      @turnoff="closeAssistancePickup()"
    />
  </v-row>
</template>

<script>
import {
  mdiAlertCircleOutline,
  mdiCheck,
  mdiAlertOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import assistanceService from '@/services/assistanceService'
import AssistanceEdit from '@/views/assistance/edit/AssistanceEdit.vue'
import PickupAssistance from '@/views/assistance/pickups/PickupAssistance.vue'

export default {
  components: { PickupAssistance, AssistanceEdit },
  props: {
    assistances: {
      required: true,
      type: [Object, Array],
    },
    refresh: {
      required: true,
      type: Function,
    },
  },

  setup(props) {
    const {
      isCompleted,
      filterByNeedScenario,
    } = assistanceService()

    const assistanceEditVisible = ref(false)
    const pickUpVisible = ref(false)
    const assistanceToEdit = ref({})

    const openAssistancePickup = assistance => {
      assistanceToEdit.value = assistance
      pickUpVisible.value = true
    }

    const closeAssistancePickup = () => {
      assistanceToEdit.value = {}
      pickUpVisible.value = false
      props.refresh()
    }

    const openAssistanceEdit = assistance => {
      assistanceToEdit.value = assistance
      assistanceEditVisible.value = true
    }

    const closeAssistanceEdit = () => {
      assistanceToEdit.value = {}
      assistanceEditVisible.value = false
    }

    filterByNeedScenario(props.assistances[0].products)

    return {
      isCompleted,
      filterByNeedScenario,
      assistanceEditVisible,
      assistanceToEdit,
      pickUpVisible,
      openAssistanceEdit,
      closeAssistanceEdit,

      openAssistancePickup,
      closeAssistancePickup,
      icons: {
        mdiAlertCircleOutline,
        mdiCheck,
        mdiAlertOutline,
      },

    }
  },
}
</script>

<style lang="scss" scoped>

.contenitore {

  display:flex;

  flex-direction: row;

  flex-wrap: wrap;

  flex-grow: 1;

  align-items: center;


  @media screen and (max-width: 480px) {

  }

  .image-container {

    width: 25%;

    max-width: 110px;

    height: 120px;

    position: relative;


    overflow: hidden;

    @media screen and (max-width: 480px) {
      width: 30%;
    }

    .image {

      position: absolute;

      top: -15px;

      scale: 1.1;

      object-fit: cover;

      @media screen and (max-width: 480px) {
        scale: 1.3;
      }

    }

  }

  .text-container {

    width: 75%;

    @media screen and (max-width: 480px) {
      width: 70%;
    }

  }

}

</style>
