<template>
  <div>
    <v-dialog
      v-model="pickupVisible"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="$emit('turnoff')"
    >
      <v-card
        tile
      >
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="$emit('turnoff')"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Assistenza {{ assistance.code }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row v-if="currentAssistance">
            <!--                    <pre>-->
            <!--                                {{ currentAssistance }}-->
            <!--                    </pre>-->

            <v-col
              cols="12"
              lg="6"
              md="8"
              sm="8"
              class="offset-lg-3 offset-sm-2 offset-md-2  mt-10"
            >
              <v-card class="mb-6 ghd-border-black">
                <div class="text-center text-large ghd-ITCAvant text-black text-uppercase">
                  Prodotti
                </div>
                <v-card-text>
                  <div
                    v-if="currentAssistance && currentAssistance.products"
                  >
                    <div
                      v-for="product in currentAssistance.products.slice(0,1)"
                      :key="currentAssistance.id+product.id"
                    >
                      <div class="contenitore">
                        <div class="image-container">
                          <img
                            class="image"
                            :src="product.info.item_icon"
                          >
                        </div>

                        <div class="text-container">
                          <div class="font-weight-semibold text-no-wrap ghd-ITCAvant text-black">
                            {{ product.prodotto.nome }}
                          </div>

                          <span class="text-xs text-no-wrap text-black">Seriale: {{ product.prodotto.seriale }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card class="ghd-border-black">
                <div class="text-center text-large ghd-ITCAvant text-black text-uppercase mb-5">
                  Prenotazione ritiro
                </div>
                <v-card-text>
                  <v-row v-if="currentAssistance.can_store_pickup">
                    <v-form
                      ref="formStorePickup"
                    >
                      <v-card
                        v-if="userData.currier === 'GLS'"
                        class="ghd-border-black mb-5"
                      >
                        <div class="text-center text-medium ghd-ITCAvant text-black text-uppercase mb-5 mt-5">
                          Seleziona la data per il ritiro
                        </div>

                        <v-col
                          cols="12"
                        >
                          <v-col
                            cols="12"
                            lg="12"
                            md="12"
                          >
                            <div v-if="userData.currier === 'GLS'">
                              <v-row>
                                <v-menu
                                  ref="menuref"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="sendData.data_choosed"
                                      label="Data prenotazione"
                                      readonly
                                      outlined
                                      dense
                                      v-bind="attrs"
                                      :rules="[validators.required]"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>

                                  <v-date-picker
                                    ref="picker"
                                    v-model="sendData.data_choosed"
                                    :min="new Date().toISOString().substr(0, 10)"
                                    color="primary"
                                    locale="it-IT"
                                    :first-day-of-week="1"
                                    :allowed-dates="allowedDates"
                                    @change="save"
                                  ></v-date-picker>
                                </v-menu>
                              </v-row>
                            </div>
                          </v-col>
                        </v-col>
                      </v-card>

                      <v-card class="ghd-border-black mb-5">
                        <v-card-text style="padding: 0px">
                          <div class="text-center text-medium ghd-ITCAvant text-black text-uppercase mb-5 mt-5">
                            Inserisci l'indirizzo per il ritiro
                          </div>
                          <v-col
                            cols="12"
                          >
                            <v-row>
                              <v-col
                                v-if="userData.currier === 'BRT'"
                                cols="12"
                                lg="4"
                                md="4"
                              >
                                <v-menu
                                  ref="menuref"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="sendData.data_choosed"
                                      label="Data prenotazione"
                                      readonly
                                      outlined
                                      dense
                                      v-bind="attrs"
                                      :rules="[validators.required]"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>

                                  <v-date-picker
                                    ref="picker"
                                    v-model="sendData.data_choosed"
                                    :min="new Date().toISOString().substr(0, 10)"
                                    color="primary"
                                    locale="it-IT"
                                    :first-day-of-week="1"
                                    :allowed-dates="allowedDates"
                                    @change="save"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col
                                cols="12"
                                lg="4"
                                md="4"
                              >
                                <v-text-field
                                  v-model="sendData.clientePickup"
                                  label="Nome e cognome"
                                  placeholder="Nome e cognome"
                                  outlined
                                  dense
                                  :rules="[validators.required]"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                lg="4"
                                md="4"
                              >
                                <v-text-field
                                  v-model="sendData.indirizzoPickup"
                                  label="Via"
                                  placeholder="Via"
                                  outlined
                                  dense
                                  :rules="[validators.required]"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                lg="4"
                                md="4"
                              >
                                <v-text-field
                                  v-model="sendData.civicoPickup"
                                  label="Civico"
                                  placeholder="Civico"
                                  outlined
                                  dense
                                  :rules="[validators.required]"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                lg="4"
                                md="4"
                              >
                                <v-select
                                  v-model="sendData.provinciaPickup"
                                  :items="province"
                                  item-text="label"
                                  item-value="value"
                                  hide-details="auto"
                                  label="Provincia"
                                  outlined
                                  dense
                                  :rules="[validators.required]"
                                ></v-select>
                              </v-col>

                              <v-col
                                cols="12"
                                lg="4"
                                md="4"
                              >
                                <v-select
                                  v-model="sendData.localitaPickup"
                                  :items="filterCityByProvince(sendData.provinciaPickup)"
                                  item-text="label"
                                  item-value="value"
                                  hide-details="auto"
                                  label="Citta"
                                  placeholder="Citta"
                                  outlined
                                  dense
                                  :rules="[validators.required]"
                                ></v-select>
                              </v-col>
                              <v-col
                                cols="12"
                                lg="4"
                                md="4"
                              >
                                <v-text-field
                                  v-model="sendData.capPickup"
                                  label="Cap"
                                  placeholder="Cap"
                                  outlined
                                  dense
                                  :rules="[validators.required]"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                lg="4"
                                md="4"
                              >
                                <v-text-field
                                  v-model="sendData.phonePickup"
                                  label="Telefono"
                                  placeholder="Telefono"
                                  outlined
                                  dense
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-card-text>
                      </v-card>
                      <div class="text-medium ghd-ITCAvant text-black text-uppercase mb-4">
                        Ricorda di stampare il QrCode ricevuto via mail:
                        <ul style="list-style-type: decimal;">
                          <li>Applica una copia all'esterno del pacco</li>
                          <li>Inserisci una copia all'interno del pacco insieme al prodotto</li>
                        </ul>
                      </div>
                      <v-btn
                        color="primary"
                        large
                        block
                        @click="handleDispatchStorePickup()"
                      >
                        Prenota ritiro
                      </v-btn>
                    </v-form>
                  </v-row>
                  <v-row
                    v-if="currentAssistance && currentAssistance.pickups && currentAssistance.pickups.length"
                    :class="currentAssistance.can_store_pickup ? 'mt-4' : ''"
                  >
                    <v-col
                      v-for="pickup in currentAssistance.pickups"
                      :key="pickup.id"
                      cols="12"
                      lg="6"
                      md="6"
                    >
                      <v-card class="ghd-border-black">
                        <v-card-title v-if="pickup.n_ritiro">
                          N ritiro: <strong>{{ pickup.full_n_ritiro }}</strong>
                        </v-card-title>

                        <v-card-text class="text-black text-medium">
<!--                          {{ pickup.status_label_text }}-->
<!--                          <br />-->
                          <span v-if="pickup.status_desc">{{ pickup.status_desc }}</span>
                          <v-btn
                            v-if="pickup.currier === 'GLS'"
                            color="primary"
                            block
                            small
                            class="mt-4"
                            @click="handleDispatchTrackingPickup(pickup)"
                          >
                            Traccia ritiro
                          </v-btn>

                          <v-btn
                            v-if="!cannotDeletePickup(pickup)"
                            color="primary"
                            block
                            small
                            class="mt-4"
                            @click="handleDispatchDeletePickup(pickup)"
                          >
                            Cancella ritiro
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              lg="6"
              md="12"
              sm="12"
              class="offset-3"
            >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialog-white
      v-if="isTrackingDialogVisible"
      @turnoff="closeTrackingDialog"
    >
      <template #content>
        <div v-if="trackingData.ritiro && trackingData.ritiro.length">
          <div class="ghd-text-black ghd-ITCAvant text-uppercase text-black ml-6 text-large">
            Dettagli ritiro
          </div>
          <v-card-text>
            <v-data-table
              :headers="headerRitiro"
              :items="trackingData.ritiro"
              hide-default-footer
              disable-sort
            />
          </v-card-text>
        </div>

        <div v-if="trackingData.spedizione && trackingData.spedizione.length">
          <div class="ghd-text-black ghd-ITCAvant text-uppercase text-black ml-6 text-large">
            Dettagli spedizione
          </div>
          <v-card-text>
            <v-data-table
              :headers="headerRitiro"
              :items="trackingData.spedizione"
              hide-default-footer
              disable-sort
            />
          </v-card-text>
        </div>
      </template>
    </dialog-white>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'
import { getUserData } from '@core/utils'
import VueTimepicker from 'vue2-timepicker'
import assistanceService from '@/services/assistanceService'
import 'vue2-timepicker/dist/VueTimepicker.css'
import {
  required,
} from '@core/utils/validation'
import dayjs from 'dayjs'
import registrationsService from '@/services/registrationsService'
import DialogWhite from '@/views/dialogs/dialogWhite.vue'

export default {
  components: {
    VueTimepicker,
    DialogWhite,
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    assistance: {
      required: true,
      type: [Object, Array],
    },
  },
  setup(props) {
    const menu = ref(false)

    // const picker = ref(null)
    const menuref = ref(null)

    const save = d => {
      menuref.value.save(d)
    }

    const {
      province,
      comuni,
      generateProvincia,
      generateComune,
      filterCityByProvince,
    } = registrationsService()

    generateProvincia()
    generateComune()

    const {
      currentAssistance,

      handleStorePickup,
      handleDeletePickup,
      handleTrackingPickup,
      getAssistance,
      fetchAssistance,
    } = assistanceService()

    const userData = getUserData()
    const pickupVisible = ref(false)
    const formStorePickup = ref(null)
    const trackingData = ref({})
    const isTrackingDialogVisible = ref(false)

    const headerRitiro = ref([
      { text: 'Data', value: 'Data' },
      { text: 'Ora', value: 'Ora' },
      { text: 'Luogo', value: 'Luogo' },
      { text: 'Stato', value: 'Stato' },
    ])

    watch(() => props.visible, newVal => {
      pickupVisible.value = newVal
      if (pickupVisible.value) {
        fetchAssistance(props.assistance.code)
      }
    })

    const sendData = ref({
      data_choosed: null,

      // dati per il nuovo indirizzo
      clientePickup: '',
      indirizzoPickup: '',
      civicoPickup: '',
      localitaPickup: '',
      capPickup: '',
      provinciaPickup: '',
      phonePickup: '',
    })

    const isWeekend = dateToCheck => {
      const day = (new Date(dateToCheck)).getDay()
      let month = new Date().getMonth() + 1
      month = `0${month}`.slice(-2)
      const today = `${new Date().getFullYear()}-${month}-${new Date().getDate()}`

      return day === 6 || day === 0 || today === dateToCheck// 6=sabato, 0=domenica
    }

    const allowedDates = computed(() => dateToCheck => !isWeekend(dateToCheck))
    const refetchData = () => {
      fetchAssistance(props.assistance.code)
    }

    const mappingSendDataForStorePickup = () => {
      const convertedObject = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const key in sendData.value) {
        if (typeof sendData.value[key] === 'object' && sendData.value[key]) {
          // eslint-disable-next-line radix
          convertedObject[key] = parseInt(sendData.value[key].HH)
        } else {
          convertedObject[key] = sendData.value[key]
        }
      }

      return convertedObject
    }

    const handleDispatchStorePickup = () => {
      if (formStorePickup.value.validate()) {
        sendData.value.ass_code = getAssistance().code

        handleStorePickup(mappingSendDataForStorePickup()).then(() => {
          refetchData()
        })
      }
    }

    const handleDispatchDeletePickup = pickup => {
      handleDeletePickup(pickup).then(() => {
        refetchData()
      })
    }

    const closeTrackingDialog = () => {
      isTrackingDialogVisible.value = !isTrackingDialogVisible.value
      trackingData.value = {}
    }

    const handleDispatchTrackingPickup = pickup => {
      handleTrackingPickup(pickup).then(response => {
        isTrackingDialogVisible.value = !isTrackingDialogVisible.value
        trackingData.value = response.data.data
      })
    }

    const cannotDeletePickup = pickup => (pickup.status >= 3) || (pickup.currier === 'BRT' && pickup.data_to_excel && dayjs(pickup.created_at).isAfter(dayjs().subtract(10, 'day'))) || (dayjs.unix(pickup.date_booked).isAfter(dayjs().subtract(1, 'day')))

    return {
      menu,
      menuref,
      pickupVisible,
      currentAssistance,
      sendData,
      userData,
      formStorePickup,

      province,
      comuni,
      allowedDates,
      trackingData,
      isTrackingDialogVisible,
      headerRitiro,

      save,
      cannotDeletePickup,
      refetchData,
      getAssistance,
      handleDispatchStorePickup,
      filterCityByProvince,
      handleDispatchDeletePickup,
      handleDispatchTrackingPickup,
      closeTrackingDialog,
      icons: {
        mdiClose,
      },
      validators: {
        required,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

.contenitore {

  display:flex;

  flex-direction: row;

  flex-wrap: wrap;

  flex-grow: 1;

  align-items: center;

  @media screen and (max-width: 480px) {

  }

  .image-container {

    width: 25%;

    max-width: 110px;

    height: 120px;

    position: relative;

    overflow: hidden;

    @media screen and (max-width: 480px) {
      width: 30%;
    }

    .image {

      position: absolute;

      top: -15px;

      scale: 1.1;

      object-fit: cover;

      @media screen and (max-width: 480px) {
        scale: 1.3;
      }

    }

  }

  .text-container {

    width: 75%;

    @media screen and (max-width: 480px) {
      width: 70%;
    }

  }

}

</style>
