<template>
  <div class=" auth-v2">
    <div class="auth-inner">
      <assistenze-table
        v-if="assistances && assistances.length"
        :assistances="assistances"
        :refresh="refresh"
      ></assistenze-table>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiArrowRightDropCircleOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { onMounted } from '@vue/composition-api'
import AssistenzeTable from '@/views/dashboard/AssistenzeTable.vue'
import store from '@/store'
import router from '@/router'

export default {
  components: { AssistenzeTable },
  props: {
    assistances: {
      required: true,
      type: Array,
    },
    refresh: {
      required: true,
      type: Function,
    },
  },
  setup(props) {
    onMounted(() => {
      if (!props.assistances || !props.assistances.length) {
        store.commit('assistance/setSearchedOrder', 'empty')
        router.push({ name: 'assistenza-searchitem' })
      }
    })

    return {
    //   assistances,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiArrowRightDropCircleOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
