<template>
  <v-dialog
    v-model="assistanceEditVisible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    @keydown.esc="$emit('turnoff')"
  >
    <v-card
      tile
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="$emit('turnoff')"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title>Assistenza {{ assistance.code }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!--          <v-btn-->
          <!--            dark-->
          <!--            text-->
          <!--            class="my-auto"-->
          <!--            @click="$emit('turnoff')"-->
          <!--          >-->
          <!--            Salva-->
          <!--          </v-btn>-->
        </v-toolbar-items>
      </v-toolbar>

      <div class="app-content-container">
        <v-row
          v-if="currentAssistance && currentAssistance.cliente"
          class="mt-6"
        >

          <v-col
            v-if="!currentAssistance.closed_at"
            cols="10"
            sm="10"
            md="8"
            lg="8"
            offset-sm="1"
            offset-md="2"
            offset-lg="2"
            offset="1"
          >
            <div class="text-right">
              <v-btn
                color="primary"
                x-small
                rounded
                @click="dispatchHandleDownloadEtichetta"
              >
                Scarica etichetta
              </v-btn>
            </div>
          </v-col>

          <v-col
            cols="10"
            sm="10"
            md="8"
            lg="8"
            offset-sm="1"
            offset-md="2"
            offset-lg="2"
            offset="1"
          >
            <v-card class="ghd-border-black">
              <v-card-text>
                <div>
                  <span>Nome :</span> <span class="font-weight-bold">{{ currentAssistance.cliente.fullname }}</span>
                </div>
                <div>
                  <span>Email :</span> <span class="font-weight-bold">{{ currentAssistance.cliente.email }}</span>
                </div>
                <div>
                  <span>Identificativo :</span> <span class="font-weight-bold">{{ currentAssistance.cliente.tax }}</span>
                </div>
              </v-card-text>
            </v-card>
            <!--            <column-right :assistance="currentAssistance" />-->
          </v-col>
        </v-row>
        <column-left
          v-if="currentAssistance"
          :assistance="currentAssistance"
          @refetchdata="refetchData()"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'

import { ref, watch } from '@vue/composition-api'

import ColumnLeft from '@/views/assistance/edit/ColumnLeft.vue'
import assistanceService from '@/services/assistanceService'

export default {
  components: {
    ColumnLeft,
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    assistance: {
      required: true,
      type: [Object, Array],
    },
  },
  setup(props) {
    const {
      currentAssistance,
      getAssistance,

      fetchAssistance,
      handleDownloadEtichetta,
    } = assistanceService()

    const assistanceEditVisible = ref(false)

    watch(() => props.visible, newVal => {
      assistanceEditVisible.value = newVal
      if (assistanceEditVisible.value) {
        fetchAssistance(props.assistance.code)
      }
    })

    const refetchData = () => {
      fetchAssistance(props.assistance.code)
    }

    const dispatchHandleDownloadEtichetta = () => {
      handleDownloadEtichetta({ assistenza: currentAssistance.value.code }).then(response => {
        const { url } = response.data.data
        if (url) {
          window.open(url, '_blank')
        }
      })
    }

    return {
      getAssistance,
      currentAssistance,
      refetchData,

      assistanceEditVisible,
      dispatchHandleDownloadEtichetta,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
